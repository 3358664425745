<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("mine.my_order") }}
      <!--  -->
      <div class="ctrl">
        <span @click="toDel" v-show="isEdit">{{ $t("CM_Delete") }}</span>
        <!-- 删除 -->
        <span @click="isEdit = !isEdit">{{
          isEdit ? $t("BN_Comm_Completed") : $t("BN_Comm_Edit")
        }}</span>
        <!-- "完成" : "编辑" -->
      </div>
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 类别 -->
      <ListFilter
        only
        :name="$t('XB_Category')"
        :dataSource="reviewStatus"
        @onChange="switchType"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="searchParams.keyword"
              :placeholder="$t('order.please_enter_package_name')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入培训包名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <a-spin :spinning="loading">
      <div class="list" v-if="dataList.length">
        <div class="dl" v-for="(item, index) in dataList" :key="index">
          <div class="check" v-show="isEdit">
            <a-checkbox v-model:checked="item.isDel" />
          </div>
          <div class="dt">
            <a-tag>{{
              item.buyType == 1
                ? $t("package.personal_purchase", 2)
                : $t("package.enterprise_purchase", 2)
            }}</a-tag>
            <!-- "个人" : "单位" 购买 -->
            <span class="payTime"
              >{{ $t("order.purchase_time") }}：{{
                dateFormat(item.payTime)
              }}</span
            >
            <!-- 购买时间 -->
            <span class="orderId"
              >{{ $t("CM_OrderId") }}：{{ item.orderId }}</span
            >
            <!-- 订单号： -->
            <span
              class="btnCode"
              v-if="item.buyType == 2 && !isEdit"
              @click="viewsCode(item.orderId)"
              >{{ $t("order.activation_code") }}</span
            >
            <!-- 激活码 -->
          </div>
          <div class="dd">
            <div class="info">
              <img :src="item.coverImg" />
              <div class="cont">
                <p class="title">{{ item.taskName }}</p>
                <p>
                  <!-- 有效期： -->
                  {{ $t("validity_period") }}：{{ item.effective
                  }}<template v-if="item.dateType == 1">{{
                    $t("Pub_Day")
                  }}</template>
                  <template v-if="item.dateType == 2">{{
                    $t("order.month")
                  }}</template>
                  <template v-if="item.dateType == 3">{{
                    $t("CM_Year")
                  }}</template>
                </p>
                <p class="more-2">
                  {{ $t("order.purchase_quantity") }}：{{ item.buyNum
                  }}{{ $t("LB_My_Ge") }}
                </p>
                <!-- 购买数量： 个-->
              </div>
            </div>
            <div class="price">
              {{ $t("order.purchasing_price") }}：<em class="num"
                >{{ $t("currency_symbol") }}
                {{ item.totalPrice.toFixed(2) }}</em
              >
              <!-- 购买金额： -->
            </div>
          </div>
        </div>
        <div class="page-Wrap">
          <a-pagination
            show-quick-jumper
            hideOnSinglePage
            :defaultPageSize="10"
            v-model:current="searchParams.page"
            :total="Total"
            @change="pageChange"
          >
            <template #itemRender="{ type, originalElement }">
              <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
              <a class="page-a" v-else-if="type === 'next'">{{
                $t("cm_next")
              }}</a>
              <renderVNode v-else :vnode="originalElement"></renderVNode>
            </template>
          </a-pagination>
        </div>
      </div>
      <a-empty
        v-else
        :image="require('@/assets/image/no_data_3.png')"
        :image-style="{ height: '186px' }"
        style="padding: 60px 0"
      />
    </a-spin>
  </div>
  <!-- 激活码列表 -->
  <a-drawer
    :title="$t('order.Activation_code_list')"
    width="400"
    :visible="codeShow"
    @close="codeShow = false"
  >
    <div class="code-list">
      <div class="item" v-for="item in codeList" :key="item.code">
        <div class="row">
          <div>{{ $t("order.activation_code") }}：{{ item.code }}</div>
          <!-- 激活码 -->
          <div :class="{ gary: item.useUid === 0 }">
            {{
              item.useUid === 0
                ? $t("order.use_state_option", 2)
                : $t("order.use_state_option", 1)
            }}
            <!-- "未使用" : "已使用" -->
          </div>
        </div>
        <div class="row" v-if="item.useUid != 0">
          <div>{{ $t("order.user") }}：{{ item.useUser }}</div>
          <!-- 使用人 -->
        </div>
        <div class="row" v-if="item.useUid != 0">
          <div>
            {{ $t("order.usage_time") }}：{{ dateFormat(item.useTime) }}
          </div>
          <!-- 使用时间 -->
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance, createVNode } from "vue";
import { orderList, delsOrder, orderCodes } from "@/api/user";
import { dateFormat } from "@/utils/tools";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import ListFilter from "@/components/new/filter/ListFilter.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      isEdit: false,
      reviewStatus: [
        { id: 1, name: $t("CM_Personal") }, //个人
        { id: 2, name: $t("CM_Unit") }, //单位
      ],
      searchParams: {
        page: 1,
        pageSize: 10,
        type: 0,
        keyword: "",
      },
      dataList: [],
      loading: true,
      Total: 0,
      codeShow: false,
      codeList: [],
    });

    const pageChange = (n) => {
      state.searchParams.page = n;
      getList();
    };

    const getList = () => {
      state.loading = true;
      orderList(state.searchParams).then((res) => {
        state.loading = false;
        state.Total = res.data.totals;
        state.dataList = res.data.list || [];
      });
    };

    const onSearch = (value) => {
      state.searchParams.page = 1;
      getList();
    };

    const toDel = () => {
      let ids = [];
      state.dataList.map((item) => {
        if (item.isDel) {
          ids.push(item.orderId);
        }
      });
      if (ids.length) {
        Modal.confirm({
          title: () => $t("XB_AreYouSureDeleteOrder"),
          // 确定删除订单吗？
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            delsOrder({ orderList: ids }).then((res) => {
              proxy.$message.success($t("CM_Success"));
              // 操作成功
              state.isEdit = false;
              getList();
            });
          },
        });
      } else {
        proxy.$message.warning($t("please_select_data"));
      }
    };

    const switchType = (arr) => {
      state.searchParams.type = arr[0];
      state.searchParams.page = 1;
      getList();
    };

    const viewsCode = (orderId) => {
      orderCodes({ orderId }).then((res) => {
        if (res.ret == 0) {
          state.codeList = res.data;
          state.codeShow = true;
        }
      });
    };

    getList();

    return {
      ...toRefs(state),
      switchType,
      onSearch,
      pageChange,
      dateFormat,
      toDel,
      viewsCode,
      renderVNode,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
    .mixinFlex(space-between; center);
    .ctrl {
      color: var(--theme);
      cursor: pointer;
      user-select: none;
      .mixinFlex(flex-end);
      font-size: 16px;
      font-weight: normal;
      span {
        margin-left: 10px;
      }
    }
  }

  .filter-wrap {
    padding-bottom: 12px;
    border-bottom: 1px solid #f4f4f4;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .list {
    .dl {
      position: relative;
      width: 100%;
      height: 208px;
      margin-bottom: 24px;
      position: relative;
      .dt {
        color: #333333;
        font-size: 14px;
        padding: 24px;
        background: #f9f9f9;
        .ant-tag {
          color: #ea3553;
          border-color: #ea3553;
          padding: 0 12px;
        }
        .payTime,
        .orderId {
          margin-left: 28px;
        }
        .btnCode {
          cursor: pointer;
          color: @color-theme;
          float: right;
        }
      }
      .dd {
        width: 100%;
        .mixinFlex();
        .info {
          width: 579px;
          padding: 24px;
          border: 1px solid #eeeeee;
          border-top: none;
          border-right: none;
          .mixinFlex();
          img {
            width: 160px;
            height: 90px;
            margin-right: 16px;
          }
          .cont {
            width: calc(100% - 176px);
            p {
              font-size: 14px;
              margin-bottom: 10px;
            }
            .title {
              font-size: 16px;
              .mixinEllipsis(24px);
            }
            .more-2 {
              margin-bottom: 0px;
            }
          }
        }
        .price {
          border: 1px solid #eeeeee;
          border-top: none;
          padding: 24px;
          width: calc(100% - 579px);
          .mixinFlex(center, center);
          font-size: 16px;
          color: #666666;
          .num {
            color: #ea3553;
          }
        }
      }
      .check {
        position: absolute;
        right: 24px;
        top: 24px;
      }
    }
  }
}
.code-list {
  .item {
    margin-bottom: 10px;

    .row {
      .mixinFlex(space-between);
      line-height: 1.5;
      font-size: 14px;

      .gary {
        color: #999;
      }
    }
  }
}

.page-Wrap {
  .page-a {
    padding: 0 4px;
    height: 32px;
    display: inline-block;
  }

  ::v-deep(.ant-pagination-item-active) {
    border: 1px solid @color-theme;

    a {
      color: @color-theme !important;
    }
  }
}
</style>
